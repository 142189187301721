import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';

import CollapsibleCard from './CollapsibleCard';

import config from "./../config";
import helper from './../helper'

function UppflettiordList(props) {
	const [data, setData] = useState(null);

	const url = config.apiRoot+'/api/uppflettiord/';

	useEffect(() => {
			fetchData();

			console.log(props)
	}, [props.searchParams]);

	const parseSearchParams = (paramStr) => {
		let params = {};

		paramStr = decodeURI(paramStr);

		if (paramStr.substr(0, 1) == '?') {
			paramStr = paramStr.substr(1);
		}

		if (paramStr.split('&').length > 0) {
			paramStr.split('&').forEach((item, i) => {
				if (item.split('=')[0]) {
					params[item.split('=')[0]] = item.split('=')[1];
				}
			});
		}

		return params;
	};

	const fetchData = () => {
		let params = {};
		let urlParams = [];

		if (props.searchParams) {
			params = parseSearchParams(props.searchParams);
		}

		if (params.lykilord) {
			urlParams.push('lykilord='+params.lykilord);
		}

		if (params.leit) {
			urlParams.push('leit='+params.leit);
		}

		console.log(params)

		fetch(url+(urlParams.length > 0 ? '?'+urlParams.join('&') : ''))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				setData(json.results);
			});
	}

	const formatHtml = (html) => {
		return html.replace(/[><]/g, (match) => {
			let replacement = {
				'>': '<strong>',
				'<': '</strong>'
			};

			return replacement[match];
		});
	}

	return (
		props.columnList ?
		<div className="d-flex align-content-around flex-wrap">
			{
				data && data.length > 0 &&
				data.map((item, index) => <div className="col-4 mb-3">
					<Link to={'/daemi/'+item.id}>{item.ord}</Link> <small className="">{item.daemi == 0 ? ' (engin dæmi)' : ''}</small>
				</div>)
			}
		</div> :
		<div>
			{
				data && data.length > 0 &&
				data.map((item, index) => <div>
					<Link className="h4" to={'/daemi/'+item.id}>{item.ord}</Link>
					{
						item.hljodritun &&
						<React.Fragment><br/>{item.hljodritun}</React.Fragment>
					}
					<hr/>
				</div>)
			}
		</div>
	);
}

export default UppflettiordList;
