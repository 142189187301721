export default {
	pageSize: 100,

	apiRoot: '//samtalsordabok.arnastofnun.is/django',
	//apiRoot: 'http://130.208.108.18:8000',

	vefurRoot: '//samtalsordabok.arnastofnun.is/django/vefur',
	//vefurRoot: 'http://130.208.108.18:8000/django/vefur',
	mediaRoot: '//samtalsordabok.arnastofnun.is/django/media/',

	siteTitle: 'Samtalsorðabók',

	gaTrackingId: 'G-71GL9QWG5S'
}
