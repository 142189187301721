import React, { useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';

import config from "./../config";
import helper from './../helper'

function AudioPlayer(props) {
	const audioEl = useRef(null);

	useEffect(() => {
		audioEl.current.pause();
        audioEl.current.load();
	}, [props.src]);

	return (
		<audio ref={audioEl} controls>
			<source src={props.src} type="audio/mpeg" />
			Vafrinn þinn styður ekki hljóðspilun.
		</audio>
	);
}

export default AudioPlayer;
