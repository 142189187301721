import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AutocompleteInput from './AutocompleteInput';

import config from './../config';
import helper from './../helper'

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.inputKeyPressHandler = this.inputKeyPressHandler.bind(this);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.formatAutocompleteList = this.formatAutocompleteList.bind(this);
		this.searchBoxItemSelectHandler = this.searchBoxItemSelectHandler.bind(this);

		this.state = {
			searchBoxInput: ''
		};
	}

	inputKeyPressHandler(event) {
		if (event.key == 'Enter') {
			this.executeSearch();
		}
	}

	inputChangeHandler(event) {
		let stateObj = {};

		stateObj[event.target.name] = event.target.value;

		this.setState(stateObj);

		this.refs.searchInput.refs.inputField.focus();
	}

	componentDidMount() {
		if (this.props.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}

		if (!this.state.lykilord) {
			fetch(config.apiRoot+'/api/lykilord/')
				.then(res => res.json())
				.then(json => this.setState({
					lykilord: json.results
				}));
		}

		this.refs.searchInput.refs.inputField.focus();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.searchString != prevProps.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}
	}

	searchBoxItemSelectHandler(item) {
		this.props.history.push('/fletta/'+item.id);
	}

	executeSearch() {
		this.props.history.push('/leit/?leit='+this.state.searchBoxInput);
	}

	formatAutocompleteList(item) {
		return <div className="pt-2 pb-2">
			<div>{item.fletta}{item.rnum && <small> {item.rnum}</small>}{item.ordflokkur && <small className="color-blue"> {helper.ordflokkur(item.ordflokkur)}</small>}</div>
		</div>;
	}

	render() {
		return (
			<div className="form-group row">
				<div className="col-6 col-md-7">
					<AutocompleteInput ref="searchInput"
						inputClassName="form-control"
						responseDataField="results"
						searchUrl={config.apiRoot+'/api/flettur/sprettileit/?fletta=$s'}
						onChange={this.inputChangeHandler}
						inputName="searchBoxInput"
						value={this.state.searchBoxInput}
						type="text"
						valueField="fletta"
						onEnter={this.executeSearch}
						onItemSelect={this.searchBoxItemSelectHandler}
						placeholder="Leit í samtalsorðabók"
						minChars={2}
						selectedItemClass="active"
						disableAutoFill={false}
						headerText="Veldu grein úr lista eða ýttu á enter til að leita."
						listLabelFormatFunc={this.formatAutocompleteList.bind(this)} />
					{/*<div className="search-info">Notaðu * og _ sem algildisstafi. Dæmi: aðal* og ma_ur</div>*/}
				</div>
				<div className="col-4 col-md-4">
					<select className="form-control" onChange={(event) => {
						if (event.target.value == 0) {
							this.props.history.push('/');
						}
						else {
							this.props.history.push('/leit/?lykilord='+event.target.value);
						}
					}}>
						<option value="0"></option>
						{
							this.state.lykilord && this.state.lykilord.map((item, index) => <option key={index} value={item.id}>{item.lykilord}</option>)
						}
					</select>
				</div>
				<div className="col-2 col-md-1">
					<button onClick={this.executeSearch.bind(this)} className="btn btn-primary">Leita</button>
				</div>
			</div>
		);
	}
}

export default withRouter(SearchBox);
