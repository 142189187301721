import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';

import CollapsibleCard from './CollapsibleCard';
import AudioPlayer from './AudioPlayer';

import config from "./../config";
import helper from './../helper'

class DaemiList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/daemi/';

		this.state = {};
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.ord_id) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.match && this.props.match.params.ord_id && prevProps.match.params.ord_id != this.props.match.params.ord_id) {
			this.fetchData();
		}
	}

	fetchData() {
		fetch(this.url+'?ord='+this.props.match.params.ord_id)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					dataList: json.results
				});
			}.bind(this));

		fetch(config.apiRoot+'/api/uppflettiord/'+this.props.match.params.ord_id)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					uppflettiord: json
				})
			}.bind(this));
	}

	formatHtml(html) {
		return html.replace(/[><]/g, (match) => {
			let replacement = {
				'>': '<strong>',
				'<': '</strong>'
			};

			return replacement[match];
		});
	}

	render() {
		return (
			<React.Fragment>
				{
					this.state.uppflettiord &&
					<React.Fragment>

						<div><span className="h3">{this.state.uppflettiord.ord}</span>
							{
								this.state.uppflettiord.hljodritun && <React.Fragment>
									<br/>{this.state.uppflettiord.hljodritun}
								</React.Fragment>
							}
						</div>

						<hr/>

						{
							this.state.uppflettiord.lysing && this.state.uppflettiord.lysing != '' &&
							<p dangerouslySetInnerHTML={{__html: this.formatHtml(this.state.uppflettiord.lysing).split('\n').join('<br/>')}}></p>
						}

						<div className="row mt-2 mb-3">

							{
								this.state.uppflettiord.lykilord.length > 0 &&
								<div className="col col-6">Lykilorð: {
									this.state.uppflettiord.lykilord.map((item, index) => <React.Fragment>{index > 0 ? ', ' : ''}<Link to={'/leit/?lykilord='+item.id}>{item.lykilord}</Link></React.Fragment>)
								}
								</div>
							}

							{
								this.state.uppflettiord.millivisanir.length > 0 &&
								<div className="col col-6">Sjá einnig: {
									this.state.uppflettiord.millivisanir.map((item, index) => <React.Fragment>{index > 0 ? ', ' : ''}<Link to={'/daemi/'+item.id}>{item.ord}</Link></React.Fragment>)
								}
								</div>
							}

						</div>
					</React.Fragment>
				}

				{
					this.state.dataList && this.state.dataList.length == 0 && <div className="mb-3">
						<h5>Dæmi væntanleg</h5>
					</div>
				}

				{
					this.state.dataList && this.state.dataList.length > 0 && this.state.dataList.map((item, index) => {
						return <div className="mb-3">
							<h5>Dæmi {index+1}</h5>
							<div className={'card'}>
								<div className={'card-body'}>

									{
										item.lysing && item.lysing != '' &&
										<p>{item.lysing}</p>
									}

									<p><strong>Samhengi</strong><br/>{item.samhengi}</p>

									<div className="card">
										<pre className="card-body" dangerouslySetInnerHTML={{__html: this.formatHtml(item.texti)}}></pre>
									</div>

									<div className="row">

										<div className="col col-6">
											<strong>Hlusta á dæmi</strong><br/>
											<AudioPlayer src={config.mediaRoot+item.hljodskra_daemi} />
										</div>

										<div className="col col-6">
											<strong>Hlusta á dæmi (stakt orð)</strong><br/>
											<AudioPlayer src={config.mediaRoot+item.hljodskra} />
										</div>

									</div>

								</div>

							</div>

					</div>;
					})
				}

			</React.Fragment>
		);
	}
}

export default DaemiList;
