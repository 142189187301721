import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import PageView from './PageView';

import config from './../config';

class FrontPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {
		return (
			<React.Fragment>
				<PageView url="/" />
				<hr/>
			</React.Fragment>
		);
	}
}

export default withRouter(FrontPage);
